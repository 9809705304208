@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@keyframes beat-fade {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}

@keyframes fa-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fa-flip {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-fa-spin:hover {
animation: fa-spin 2s 1 forwards;
}

.hover-fa-flip:hover {
animation: fa-flip 2s 1 forwards;
}

.hover-fa-bounce:hover {
animation: fa-bounce 2s 1 forwards;
}

.hover-fa-beat-fade:hover {
  animation: beat-fade 2s 1 forwards; /* Apply the same animation on hover */
}

.hover-fa-beat:hover {
  animation: beat 2s 1 forwards; /* Apply the same animation on hover */
}

.animated-paragraph {
  display: inline-block;
  overflow: hidden;
}

.animated-paragraph span {
  display: inline-block;
  opacity: 0;
  transform: translateX(-100%);
  animation: reveal 0.5s forwards;
}

@keyframes reveal {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-section {
  opacity: 0;
  animation: fadeIn 2s ease-in-out;
  transition: opacity 0.8s ease-out; /* Add a transition for smoother fade in/out */
}

.animated-section.in-view {
  opacity: 1;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%); /* Start off-screen to the left */
    opacity: 0; /* Start fully transparent */
  }
  100% {
    transform: translateX(0); /* Slide to original position */
    opacity: 1; /* Fade in to fully visible */
  }
}

/* Slide-in from right */
@keyframes slideInRight {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
    opacity: 0; /* Start fully transparent */
  }
  100% {
    transform: translateX(0); /* Slide to original position */
    opacity: 1; /* Fade in to fully visible */
  }
}

/* Apply the slide-in animations */
.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 1s ease-out forwards;
}


